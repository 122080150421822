import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'
import { gsap } from 'gsap'
import SwiperObj, { FreeMode, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './style.module.sass'

export default ({ members }) => {
  SwiperObj.use([FreeMode, Mousewheel])

  const sliderSettings = {
    spaceBetween: 16,
    slidesPerView: 1.4,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    className: styles.slider,
  }

  const [active, setActive] = useState(0)
  const maxSlide = members.items?.length

  const goToSlide = index => {
    setActive(index)
    showReviews()
  }

  const goToPrevSlide = () => {
    if (active > 0) {
      setActive(active - 1)
      showReviews()
    }
  }

  const goToNextSlide = () => {
    if (active < maxSlide - 1) {
      setActive(active + 1)
      showReviews()
    }
  }

  const showReviews = () => {
    const selector = `.${styles.sectionActive} .${styles.sectionDesktop} .${styles.review}`

    gsap.set(selector, {
      y: -40,
      opacity: 0,
    })

    setTimeout(() => {
      gsap.to(selector, {
        opacity: 1,
        y: 0,
        stagger: 0.1,
        duration: 0.5,
      })
    }, 0)
  }

  useEffect(() => {
    showReviews()
  }, [])

  return (
    <div className={styles.members}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.info}>
            <p className={styles.index}>{members.index}</p>
            <h2 className={styles.title}>{members.title}</h2>
            <div className={styles.controls}>
              <div
                onClick={goToPrevSlide}
                className={cx(styles.controlsPrev, { [styles.controlsDisabled]: active === 0 })}
              />
              <div
                onClick={goToNextSlide}
                className={cx(styles.controlsNext, { [styles.controlsDisabled]: active === maxSlide - 1 })}
              />

              <div className={styles.pages}>
                {members.items &&
                  members.items.map((member, index) => (
                    <div
                      onClick={() => goToSlide(index)}
                      key={member._key}
                      className={cx(styles.page, { [styles.pageActive]: active === index })}
                    >
                      {index + 1}
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className={styles.list}>
            {members.items &&
              members.items.map((item, index) => (
                <div className={cx(styles.section, { [styles.sectionActive]: active === index })} key={item._key}>
                  <div className={styles.sectionMobile}>
                    <Swiper {...sliderSettings}>
                      {item.reviews &&
                        item.reviews.map(review => (
                          <SwiperSlide className={styles.review} key={review._key}>
                            <div className={styles.image}>
                              <img
                                className={styles.imageSrc}
                                {...srcSetProps(sanityImageUrl(review?.image))}
                                alt={review.image?.caption}
                              />
                            </div>
                            <div className={styles.info}>
                              <div className={styles.top}>
                                <span className={styles.nickname}>{review.nickname}</span>
                                <span className={styles.date}>{review.date}</span>
                                <span className={styles.time}>{review.time}</span>
                              </div>
                              <p className={styles.text}>{review.text}</p>
                            </div>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>

                  <div className={styles.sectionDesktop}>
                    {item.reviews &&
                      item.reviews.map(review => (
                        <div className={styles.review} key={review._key}>
                          <div className={styles.image}>
                            <img
                              className={styles.imageSrc}
                              {...srcSetProps(sanityImageUrl(review?.image))}
                              alt={review.image?.caption}
                            />
                          </div>
                          <div className={styles.info}>
                            <div className={styles.top}>
                              <span className={styles.nickname}>{review.nickname}</span>
                              <span className={styles.date}>{review.date}</span>
                              <span className={styles.time}>{review.time}</span>
                            </div>
                            <p className={styles.text}>{review.text}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className={styles.pagination}>
          <div className={styles.lines}>
            {members.items &&
              members.items.map((member, index) => (
                <div
                  onClick={() => goToSlide(index)}
                  key={member._key}
                  className={cx(styles.linesItem, { [styles.linesItemActive]: active === index })}
                />
              ))}
          </div>
          <div className={styles.counter}>
            {active + 1}/{maxSlide}
          </div>
        </div>
      </div>
    </div>
  )
}
