import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ header }) => {
  return (
    <div className={styles.header}>
      <iframe
        className={styles.widget}
        src="https://discord.com/widget?id=710982219837079644&theme=dark"
        width="350"
        height="500"
        allowtransparency="true"
        frameborder="0"
        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
      ></iframe>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <p className={styles.smallTitle}>{header.smallTitle}</p>
          <h1 className={styles.title}>{header.title}</h1>
          <p className={styles.description}>{header.description}</p>
          <a href={header.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
            {header.buttonText}
          </a>
        </div>
      </div>
    </div>
  )
}
