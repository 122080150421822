import React from 'react'
import { Link } from 'gatsby'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/format'
import SwiperObj, { FreeMode, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './style.module.sass'

export default ({ videos }) => {
  SwiperObj.use([FreeMode, Mousewheel])

  const sliderSettings = {
    spaceBetween: 64,
    slidesPerView: 'auto',
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    className: styles.slider,
    breakpoints: {
      0: {
        spaceBetween: 32,
      },
      701: {
        spaceBetween: 64,
      },
    },
  }

  return (
    <div className={styles.videos}>
      <div className={styles.wrap}>
        <p className={styles.index}>{videos.index}</p>

        <div className={styles.top}>
          <h2 className={styles.title}>{videos.title}</h2>
          <a target="_blank" href={videos.buttonUrl} className={styles.link}>
            {videos.buttonText}
          </a>
        </div>

        <Swiper {...sliderSettings}>
          {videos.videos &&
            videos.videos.map(video => (
              <SwiperSlide className={styles.video}>
                <a target="_blank" href={video.url} className={styles.content}>
                  <img
                    className={styles.image}
                    {...srcSetProps(sanityImageUrl(video?.image))}
                    alt={video.image?.caption}
                  />
                  <p className={styles.head}>
                    <span>{video.title}</span>
                  </p>
                  <p className={styles.duration}>
                    <span>{video.duration}</span>
                  </p>
                </a>
              </SwiperSlide>
            ))}
        </Swiper>

        <div className={styles.form}>
          <p className={styles.formTitle}>{videos.formTitle}</p>
          <form className={styles.formBox}>
            <div className={styles.formCol}>
              <label className={styles.formLabel} for="input-question">
                {videos.questionFieldTitle}
              </label>
              <input
                className={styles.formInput}
                id="input-question"
                type="text"
                placeholder={videos.questionFieldPlaceholder}
              />
            </div>
            <div className={styles.formCol}>
              <label className={styles.formLabel} for="input-email">
                {videos.emailFieldTitle}
              </label>
              <input
                className={styles.formInput}
                id="input-email"
                type="email"
                placeholder={videos.emailFieldPlaceholder}
              />
            </div>
            <div className={styles.formCol}>
              <button className={styles.formSubmit}>{videos.formSubmitButtonText}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
