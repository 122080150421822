import React from 'react'
import Layout from '../utils/layout'
import ProductSchema from '../components/Shared/Schema/Product'
import SEO from '../utils/seo'
import Header from '../components/NewCommunityPage/Header'
import Reasons from '../components/NewCommunityPage/Reasons'
import Members from '../components/NewCommunityPage/Members'
import Videos from '../components/NewCommunityPage/Videos'
import Stories from '../components/NewCommunityPage/Stories'
import Products from '../components/NewCommunityPage/Products'
import Cta from '../components/NewCommunityPage/Cta'

export default ({ pageContext: { data } }) => {
  return (
    <Layout data={data} signUpUrl="https://app.maximustribe.com/registration" signUpText="Get Started">
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      <Header header={data.header} />
      <Reasons reasons={data.reasons} />
      <Members members={data.members} />
      <Videos videos={data.videos} />
      <Stories stories={data.stories} />
      <Products products={data.products} />
      <Cta cta={data.cta} />
    </Layout>
  )
}
