import React from 'react'
import styles from './style.module.sass'

export default ({ reasons }) => {
  return (
    <div className={styles.reasons}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{reasons.title}</h2>
      </div>
    </div>
  )
}
