import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/format'
import { gsap } from 'gsap'
import SwiperObj, { FreeMode, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './style.module.sass'

export default ({ products }) => {
  SwiperObj.use([FreeMode, Mousewheel])

  const sliderSettings = {
    spaceBetween: 32,
    slidesPerView: 1.4,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    className: styles.slider,
  }

  const [active, setActive] = useState(0)

  const goToSection = index => {
    setActive(index)
    showProducts()
  }

  const showProducts = () => {
    const selector = `.${styles.sectionActive} .${styles.sectionDesktop} .${styles.product}`

    gsap.set(selector, {
      opacity: 0,
    })

    setTimeout(() => {
      gsap.to(selector, {
        opacity: 1,
        stagger: 0.15,
        duration: 0.5,
      })
    }, 0)
  }

  useEffect(() => {
    showProducts()
  }, [])

  return (
    <div className={styles.products}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <p className={styles.index}>{products.index}</p>
          <h2 className={styles.title}>{products.title}</h2>
          <p className={styles.description}>{products.description}</p>
          <div className={styles.nav}>
            {products.sections &&
              products.sections.map((item, index) => (
                <div
                  className={cx(styles.navItem, { [styles.navItemActive]: active === index })}
                  key={item._key}
                  onClick={() => goToSection(index)}
                >
                  {item.title}
                </div>
              ))}
          </div>
        </div>
        <div className={styles.list}>
          {products.sections &&
            products.sections.map((item, index) => (
              <div className={cx(styles.section, { [styles.sectionActive]: active === index })} key={item._key}>
                <div className={styles.sectionMobile}>
                  <Swiper {...sliderSettings}>
                    {item.products &&
                      item.products.map(product => (
                        <SwiperSlide className={styles.product} key={product._key}>
                          <div className={styles.product} key={product._key}>
                            <img
                              className={styles.image}
                              {...srcSetProps(sanityImageUrl(product?.image))}
                              alt={product.image?.caption}
                            />
                            <div className={styles.info}>
                              <span className={styles.infoType}>{product.type}</span>
                              <span>{product.price}</span>
                            </div>
                            <p className={styles.head}>{product.title}</p>
                            <a href={product.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
                              {product.buttonText}
                            </a>
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>

                <div className={styles.sectionDesktop}>
                  {item.products &&
                    item.products.map(product => (
                      <div className={styles.product} key={product._key}>
                        <img
                          className={styles.image}
                          {...srcSetProps(sanityImageUrl(product?.image))}
                          alt={product.image?.caption}
                        />
                        <div className={styles.info}>
                          <span className={styles.infoType}>{product.type}</span>
                          <span>{product.price}</span>
                        </div>
                        <p className={styles.head}>{product.title}</p>
                        <a href={product.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
                          {product.buttonText}
                        </a>
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
