import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/format'
import SwiperCore, { FreeMode, Mousewheel, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './style.module.sass'

SwiperCore.use([FreeMode, Mousewheel, Navigation])

export default ({ stories }) => {
  const sliderSettings = {
    spaceBetween: 64,
    slidesPerView: 4.4,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    navigation: {
      nextEl: `.stories-btn-next`,
      prevEl: `.stories-btn-prev`,
      disabledClass: `${styles.btnDisabled}`,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    className: styles.slider,
    breakpoints: {
      0: {
        slidesPerView: 1.4,
        spaceBetween: 32,
      },
      701: {
        spaceBetween: 64,
        slidesPerView: 2.4,
      },
      1100: {
        slidesPerView: 3.4,
      },
      1400: {
        slidesPerView: 4.4,
      },
    },
  }

  return (
    <div className={styles.stories}>
      <div className={styles.wrap}>
        <p className={styles.index}>{stories.index}</p>

        <div className={styles.top}>
          <h2 className={styles.title}>{stories.title}</h2>
          <div className={styles.nav}>
            <div className={cx('stories-btn-prev', styles.btnPrev)} />
            <div className={cx('stories-btn-next', styles.btnNext)} />
          </div>
        </div>

        <Swiper {...sliderSettings}>
          {stories.items &&
            stories.items.map(item => (
              <SwiperSlide className={styles.item}>
                <Link className={styles.content} to={item.url}>
                  <img
                    className={styles.image}
                    {...srcSetProps(sanityImageUrl(item?.image))}
                    alt={item.image?.caption}
                  />
                  <div className={styles.info}>
                    <span className={styles.infoType}>{item.type}</span>
                    <span className={styles.infoDuration}>{item.duration}</span>
                  </div>
                  <p className={styles.head}>{item.title}</p>
                  <p className={styles.text}>{item.text}</p>
                </Link>
              </SwiperSlide>
            ))}
        </Swiper>

        <a href={stories.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
          {stories.buttonText}
        </a>
      </div>
    </div>
  )
}
